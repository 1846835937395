/*jslint browser: true*/
/*global $, IS */

(function() {
	var els = App.els;
	var lte9 = App.lte9;
	var $questions, $buttons;
	var currentQuestion = 0;
	
	var whale = {};
	whale = {
		setup: function() {
			$questions = els.$body.find('.whale-question');
			$buttons = $questions.find('.button');
			// $("input:radio").uniform();
			// $buttons.hide();
			// $questions.not(':first').hide();
			whale.questions();
			
			var tipsyOptions = {
				gravity: 's',
				delayIn: 100,
				delayOut: 200,
				opacity : 1
			};

			if( !lte9 ){
				tipsyOptions.fade = true;
			}
			
			els.$body.find('.whale-food-types h4').tipsy(tipsyOptions);
			
			whale.feeding();
			
			if ( Modernizr.touchevents ) {
				var $textToReplace = $('.js-touch-instruction');
				var replacementText = $textToReplace.data('text');
				
				if ( replacementText ) {
					$textToReplace.text( replacementText );
				}
			}
		},
		
		questions: function() {
			$buttons.on('click', function(e) {
				e.preventDefault();
				var $button = $(this);
				$questions.eq(currentQuestion).fadeOut(function() {
					currentQuestion++;
					$questions.eq(currentQuestion).fadeIn();
				});
			});
			
			whale.questionRadios( $('.whale-radios-size'), 'c' );
			whale.questionRadios( $('.whale-radios-food'), 'd' );
		},
		
		questionRadios: function(radioGroup, answer) {
			var $radioGroup = radioGroup;
			var $radios = $radioGroup.find('input[type=radio]');

			$radios.on('change', function() {
				if ( !$radioGroup.data('completed') ) {
					var $radio = $(this);
					if ( $radio.val() === answer ) {
						$radio.parent('li').addClass('question-correct');
					} else {
						setTimeout(function() {
							$radio.parent('li').addClass('question-incorrect');
							$radios.filter('[value='+answer+']').parent('li').addClass('question-correct');
						}, 100);
					}
					$radioGroup.data('completed', true);
					
					setTimeout(function() {
						$questions.eq(currentQuestion).find('.button').fadeIn();
					}, 1000);
				}
			});
		},
		
		feeding: function() {
			var $mouth = els.$body.find('#whale-mouth');
			var $foodTypes = els.$body.find('.w-food-item');
			
			if ( Modernizr.touchevents ) {
				$foodTypes.on('click', function() {
					var type = $(this).data('food-type');
					var $modal = $('<a href="#whale-food-info-' +  type + '"></a>');
					
					$modal.overthrow({
						afterLoad: function(obj){
							obj.$container.find('.button').on('click', function(e) {
								e.preventDefault();
								$modal.overthrow('closeOverthrow');
							});
							item.attr('style','');
						}
					}).trigger('click');
				});
				
			} else {
				$foodTypes.draggable({
					containment: '.whale-feeding',
					cursor: 'move',
					stack: '.w-food-item',
					revert: true
				});

				$mouth.droppable({
					accept: '.w-food-item',
					drop: handleDrop,
					hoverClass: 'w-mouth-hover'
				});

				function handleDrop(event, ui) {
					var draggable = ui.draggable;
					var mouth = ui.draggable.data('belongs-to');

					if( mouth == 'whale-mouth' ){
						// Correct key
						dropSuccess(ui.draggable, $(this));
					} else {
						dropFail(ui.draggable, $(this));
					}
				}

				function dropSuccess(item, mouth) {
					mouth.addClass('w-mouth-success');
					var type = item.data('food-type');
				
					item.draggable('option', 'revert', false);
				
					var $modal = $('<a href="#whale-food-info-' +  type + '"></a>');
				
					setTimeout(function() {
						$modal.overthrow({
							afterLoad: function(obj){
								obj.$container.find('.button').on('click', function(e) {
									e.preventDefault();
									$modal.overthrow('closeOverthrow');
								});
								item.attr('style','');
								item.draggable('option', 'revert', true);
								mouth.removeClass('w-mouth-success');
							}
						}).trigger('click');
					}, 200);
				}
		
				function dropFail(item, mouth) {
					//
				}	
			}			
		}
	};

	whale = $.extend(App.whale, whale);
})();
