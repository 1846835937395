/*jslint browser: true*/
/*global $, IS */

(function() {
	var els = App.els;
	var $window = $(window);

	var $paths,
		$pathVideos,
		$label;
	
	var food = {};
	food = {
		setup: function() {
			$paths = els.$body.find('section.path');
			$pathVideos = $paths.find('.path-video');
			$label = $('#label');
			
			if( $paths.length){
				// Insert first intro video
				food.insertVideo( $paths.eq(0).data('id'), $paths.eq(0).find('.path-video') );
			}
			
			if( $label.length ){
				food.label();
			}
		},
		
		insertVideo: function(id, target){
		    var options = {
				id: id,
				title: false,
				byline: false,
				portrait: false,
				loop: false
			};
			
			// Load video
		    var player = new Vimeo.Player(target[0], options);

		    // When ended, show next videos
			player.on('ended', function(){
				food.onFinish(id);
			});
		},
		
		onFinish: function(id){
			var $unlocked = $paths.filter(function() {
				return $(this).is("[data-requires*="+id+"]");
			});
			var $unlockedVids = $unlocked.find('.path-video');
			var $fadeContent = $unlocked.find('.path-video, h2');
			var $pathTitle = $fadeContent.closest('.path-wrapper').find('h3.path-title');
			$('.intro-replace').html("Choose from 2 machines...");

			$fadeContent.css({
				opacity: 0
			});

			$pathTitle.css({
				opacity: 0,
				display: 'block',

			});

			$unlocked.each(function(){
				var $this = $(this);

				$this.find('h2').text( $this.data('title') );

				food.insertVideo( $this.data('id'), $this.find('.path-video') );
			});

			$.scrollTo( $unlocked.eq(0), 800, {
				easing:'easeInOutQuad',
				offset: -70
			});

			$fadeContent.animate({
				opacity: 1
			}, 300, function(){
				$(this).closest('.path').find('.video-wrapper').addClass('video-loaded');
			});

			$pathTitle.animate({
				opacity: 1
			}, 300);
		},
		
		label: function() {
			var $links = els.$body.find("#label-links li"),
				$items = els.$body.find("#label-items li"),
				$itemContents = els.$body.find("#label-content section");

			$items.css('opacity', 0).show();
		
			$items.each(function(){
				var id = $(this).attr('class');
			
				var tipsyOptions = {
					html: true,
					gravity: $.fn.tipsy.autoNS,
					delayIn: 500,
					delayOut: 200,
					opacity : 1,
					trigger: 'manual',
					title: function(){
						var content = $itemContents.filter('[data-id='+id+']').eq(0).html();
						return content;
					}
				};
			
				if( !App.lte9 ){
					tipsyOptions.fade = true;
				}
			
				$(this).tipsy(tipsyOptions);
			});
		
			$links.each(function(){
				var $this = $(this);
				var id = $this.attr('rel');
				var windowsize = $window.width();
				if (windowsize > 639) {
					$this.hover(
						function(){
							$items.filter('.'+id).stop().animate({
								opacity: 1
							}, 200, "easeInOutQuad").tipsy('show');
						},
						function(){
							$items.filter('.'+id).stop().animate({
								opacity: 0
							}, 200, "easeInOutQuad").tipsy('hide');
						}
					).click(function(e){
						e.preventDefault();
					});
				}
			});
		}
	};

	food = $.extend(App.food, food);
})();