/* 
	Carousel
	Mike Harding, Cactuslab
	
	Plugin to create a carousel out of an unordered list (or other items within a container)
	
	show 		: How many items will be shown at once (default is 4)
	interval 	: How many items to be scrolled on each prev/next click (default is 1)
	children 	: Child elements that will make up the carousel items (default is 'li')
	speed 		: Speed of animations, in milliseconds (default is 250)
	prevlabel 	: Label for previous sroll button (default is 'Prev')
	nextlabel 	: Label for next sroll button (default is 'Next')
	size 		: Optional size dimension, used for width or height depending on orientation
	orientation : Orientation of the carousel. Options are horizontal or vertical (default is 'horizontal')
	clickevent 	: Optional callback for items when they are clicked. The clicked item is passed in with the callback.
	
	Usage: 		
	
	$('ul').clcarousel({
		show: 4,
		interval: 4,
		size: 698,
		orientation: 'vertical',
		clickevent: function(){
			$(this).parent().find('li').stop().animate({ opacity : 1 });
			$(this).stop().animate({ opacity : 0.4 });
			return false;
		}
	});	
*/
(function($) {
	$.fn.clcarousel = function(options) {

		var set = $.extend({}, $.fn.clcarousel.defaultOptions, options);

		return this.each(function() {
			// var $this = $(this);

			var $wrapper,
				$carousel = $(this),
				$children = $(this).find(set.children),
				$prev,
				$next,
				horz;
			
			var isCarouselAnimating = false;
			var activeIndex = 0;
			
			if( set.orientation == 'horizontal' ){
				horz = true;
			}
			
			var clc = {
				init : function(){
					if( set.start ){
						activeIndex = set.start;
					}
					clc.detectEasing();
					clc.structure();
					clc.navigation();
					clc.animateItems();
				},
				detectEasing : function(){
					if( !$.easing.def ) {
						if( set.easing != 'swing' && set.easing != 'linear') {
							set.easing = 'swing';
						}
					}
				},
				structure : function(){
					$carousel
						.addClass('carousel')
						.wrap('<div class="carousel-mask"></div>')
						.parent()
						.wrap('<div class="carousel-wrap"></div>');
					$wrapper = $carousel.closest('.carousel-wrap');
					$wrapper.addClass( 'carousel-'+set.orientation );

					if( set.size ){
						if( horz ){
							$wrapper.find('.carousel-mask').width( set.size );
						} else {
							$wrapper.find('.carousel-mask').height( set.size );
						}
					}
					
					if( horz ){
						var itemsWidth = 0;
						$children.each(function() { 
							itemsWidth += $(this).outerWidth(true);
						});
						$carousel.width( itemsWidth );
					}
					
					clc.items();
				},
				checkIndex : function(){
					// Limit max movement to length - visible items
					var numItems = $children.length;

					if (activeIndex >= numItems - set.show) {
						$next.addClass('carousel-disabled');
						activeIndex = numItems - set.show;
					} else {
						$next.removeClass('carousel-disabled');
					}	
					if (activeIndex <= 0) {
						activeIndex = 0;
						$prev.addClass('carousel-disabled');
					} else {
						$prev.removeClass('carousel-disabled');
					}
				},
				animateItems : function(){
					clc.checkIndex();

					var carouselOffset = 0;
					for ( var i = 0; i < activeIndex; i++ ){
						if( horz ){
							carouselOffset += $children.eq(i).outerWidth(true);
						} else {
							carouselOffset += $children.eq(i).outerHeight(true);
						}
					}
					
					if( horz ){
						$carousel.stop().animate({
							left: -carouselOffset
						}, set.speed, set.easing, function() {
							isCarouselAnimating = false;
						});
					} else {
						$carousel.stop().animate({
							top: -carouselOffset
						}, set.speed, set.easing, function() {
							isCarouselAnimating = false;
						});
					}
				},
				navigation : function(){
					var $nav = '<ul class="carousel-nav"><li class="carousel-prev"><a href="#"><span>'+set.prevlabel+'</span></a></li><li class="carousel-next"><a href="#"><span>'+set.nextlabel+'</span></a></li></ul>';
					$wrapper.prepend($nav);
					
					$prev = $wrapper.find('li.carousel-prev a');
					$next = $wrapper.find('li.carousel-next a');
					
					$wrapper.find('.carousel-nav a').click(function() {
						if (!isCarouselAnimating && !($(this).hasClass('carousel-disabled'))) {
							isCarouselAnimating = true;	

							if( $(this).parent().hasClass('carousel-prev') ) {
								if( set.interval ){
									activeIndex -= set.interval;
									if( activeIndex < 0 ) {
										activeIndex = 0;
									}
								} else {
									activeIndex--;
								}
							}

							if( $(this).parent().hasClass('carousel-next') ) {
								if( set.interval ){
									activeIndex += set.interval;
									if( activeIndex >= $children.length ) {
										activeIndex = $children.length;
									}
								} else {
									activeIndex++;
								}
							}
							
							clc.animateItems();
						}
						return false;
					});
				},
				items : function(){
					$children.click(function(){
						if ( typeof set.clickevent == 'function' ) {
							clc.selectitem( $(this) );
							set.clickevent.call( $(this) );
						}
					});
				},
				selectitem : function(item){
					$children.removeClass('carousel-selected');
					$(item).addClass('carousel-selected');
				}
			}

			clc.init();
		});
	};
	
	$.fn.clcarousel.defaultOptions = {
		show : 4,
		interval : null,
		children : 'li',
		speed : 250,
		prevlabel : 'Prev',
		nextlabel : 'Next',
		size : null,
		orientation : 'horizontal',
		clickevent : null,
		easing : 'easeOutExpo',
		start : null
	};
	
})(jQuery);
