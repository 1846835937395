/*jslint browser: true*/
/*global $, IS */

(function() {
	var els = App.els;
	
	var $toggles,
		$maori,
		$english,
		$letters,
		$sections,
		$sectionsContainer;
	
	var home = {};
	home = {
		setup: function() {
			$toggles = els.$body.find('.lang-toggle');
			$maori = $('#home-maori');
			$english = $('#home-english');

			// $sectionsContainer = els.$body.find('.sections ul');
			// $sections = els.$body.find('.sections li');
			
			$maori.hide();
			
			$toggles.click(function(e){
				e.preventDefault();
				console.log('click');
		
				var $this = $(this);
		
				if( $this.hasClass('-selected') ){
					// Do nothing, this language is already active
				} else {
					if( $this.data('lang') == 'english' ){
						console.log('english');
						$maori.hide();
						$english.show();
						selectLangToggle($this);
					} else if( $this.data('lang') == 'maori' ){
						console.log('maori');
						$english.hide();
						$maori.show();
						selectLangToggle($this);
					}
				}
		
				function selectLangToggle(el){
					$toggles.removeClass('-selected');
					el.addClass('-selected');
				}
			});
		}		
	};

	home = $.extend(App.home, home);
})();