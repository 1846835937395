/*jslint browser: true*/
/*global $, IS */

(function() {
	var els = App.els;
	
	var $links,
		$outlines,
		$timeline,
		speed = 100;
	
	var fossils = {};
	fossils = {
		setup: function() {
			$links = els.$body.find('.js-fossil-map .link');
			$outlines = els.$body.find('.js-fossils-collection .item');
			$timeline = els.$body.find('.js-fossils-timeline li');
		
			if( !App.lte9 ){
				$outlines.css({
					opacity: 0,
					display: 'block'
				});
			} else {
				$outlines.hide();
			}
		
			$links.each(function(index){
				var $this = $(this),
					id,
					$outline,
					$period;
				
				id = $this.data('class');
				$outline = $outlines.filter('[data-id='+id+']').eq(0);
				$period = $timeline.filter('[data-period='+$this.data('period')+']').eq(0);

				$this.hover(
					function(){
						if( App.lte9 ){
							$outline.show();
						} else {
							$outline.stop().animate({ opacity: 1 }, speed);
						}
						$period.addClass('-selected');
					},
					function(){
						if( App.lte9 ){
							$outline.hide();
						} else {
							$outline.stop().animate({ opacity: 0 }, speed);
						}
						$period.addClass('-selected');
						$period.removeClass('-selected');
					}
				);
			});
			
			$links.overthrow();
			
			$('.js-close-timeline').on('click', function(e) {
				e.preventDefault();
				$('body').removeClass('mobile-timeline-open');
			});
		
			$('.js-open-timeline').on('click', function(e) {
				e.preventDefault();
				$('body').addClass('mobile-timeline-open');
			});
		
			// Shadowbox.init({
			//     skipSetup: true
			// });
			//
			// Shadowbox.setup($links, {
			// 	width: 495,
			// 	height: 705,
			// 	overlayOpacity: 0.6
			// });
		}
	};

	fossils = $.extend(App.fossils, fossils);
})();
