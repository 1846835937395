function muscleGame(){
	var $links = $('#bones area, #muscles area'),
		$parts = $('#bones li, #muscles li'),
		speed = 100,
		counter,
		time = 10,
		countdown,
		$muscles = $('#muscles'),
		$bones = $('#bones'),
		$timer = $('#muscle-timer'),
		$next = $('#muscle-next'),
		$name = $('#muscle-name'),
		$answers = $('.muscle-answers'),
		$yes = $('#muscle-yes'),
		$no = $('#muscle-no'),
		$timeup = $('#muscle-times-up'),
		$pages = $('#muscle-pages'),
		$page = $('#muscle-pages .muscle-page'),
		$total = $('#muscle-pages .muscle-total'),
		$game = $('#muscle-game'),
		$results = $('#muscle-results'),
		$start = $('#muscle-start'),
		$startbutton = $('#muscle-start .start-game'),
		$startover = $('#muscle-results .start-over'),
		$ready = $('.muscle-answers .ready-for-next'),
		questions = [],
		showHovers = false,
		q_current,
		q_correct = 0,
		q_count = -1,
		q_total = 10,
		lte9 = false,
		currentType = 'muscles';
	
	$next.hide();
	$page.html(q_count+1);
	$total.html(q_total);
	
	if ( $('html').hasClass('lte9') ) {
		lte9 = true;
	}
	
	function setupParts(){
		$parts.removeClass('selected');
		if ( !Modernizr.touchevents ) {
			if( !lte9 ){
				$parts.css({
					opacity: 0,
					display: 'block'
				});
			} else {
				$parts.hide();
			}
		}			
	}
	
	function timer(){
		counter = setInterval(function(){
			if( countdown > 1 ){
				countdown--;
				$timer.html(countdown);
			} else {
				$timer.html(0);
				checkAnswer(-1);
				return;
			}
		}, 1000);
	}
	
	function resetTimer(){
		countdown = time;
		$timer.html(countdown);
		var wait = setTimeout(function(){
			timer();
			clearTimeout(wait);
		}, 500);
	}
	
	// set up questions
	$parts.each(function(){
		var $this = $(this);

		questions.push({
			"id" : $this.data('id'),
			"name" : $this.data('name'),
			"type" : $this.closest('#muscles').length ? 'muscles' : 'bones'
		});
	});
	
	setupParts();
	
	$links.each(function(index){
		var $this = $(this),
			id,
			$part;
		
		id = $this.data('id');
		$part = $parts.filter('[data-id='+id+']');
		
		if ( !Modernizr.touch ) {
			$this.hover(
				function(){
					if( showHovers ){
						if( lte9 ){
							$part.show();
						} else {
							$part.stop().animate({ opacity: 1 }, speed);
						}							
					}
				},
				function(){
					if( showHovers ){
						if( lte9 ){
							$part.hide();
						} else {
							$part.stop().animate({ opacity: 0 }, speed);
						}
					}
				}
			);
		}
	});
	
	$links.addClass('inactive');
	
	function bindClicks(){
		$links.each(function(){
			var $this = $(this);
			var id = $this.data('id');
			
			$this.click(function(e){
				e.preventDefault();
				$parts.filter('[data-id='+id+']').addClass('selected');
				checkAnswer(id);
			});
		});
	}
	
	function checkAnswer(id){
		clearTimeout(counter);
		$links.unbind('click');
		showHovers = false;
		$links.addClass('inactive');
		
		if( q_count + 1 == q_total ){
			$ready.hide();
		}
		
		if(id == q_current){
			$yes.show();
			q_correct++;
		} else if( id == -1) {
			$timeup.show();
		} else {
			$no.show();
		}
		
		$answers.show();
		
		stepThrough();
	}
	
	function stepThrough(){
		if(q_count < q_total-1){
			var next = setTimeout(function(){
				$answers.fadeOut(300, function(){
					nextQuestion();
				});
			}, 2500);
		} else {
			var showresults = setTimeout(function(){
				if( lte9 ){
					$game.hide();
					$('html').removeClass('game-in-progress');
					$name.html('&nbsp;');
					resetAnswers();
					$results.find('.correct').html(q_correct);
					$results.find('.total').html(q_total);
					$results.show();
					$.scrollTo( $('.center-col'), 500, {
						easing:'easeInOutQuad'
					});
				} else {
					$game.fadeOut('slow',function(){
						$('html').removeClass('game-in-progress');
						$name.html('&nbsp;');
						resetAnswers();
						$results.find('.correct').html(q_correct);
						$results.find('.total').html(q_total);
						$results.fadeIn();
						$.scrollTo( $('.center-col'), 500, {
							easing:'easeInOutQuad'
						});
					});
				}
			}, 2500);
		}
	}
	
	function resetAnswers(){
		$answers.hide();
		$yes.hide();
		$no.hide();
		$timeup.hide();
		$next.hide();
		$timer.html(time);
		setupParts();
		$ready.show();
	}
	
	function nextQuestion(){
		resetAnswers();
		
		q_count++;
		q_current = questions[q_count].id;
		
		$name.html(questions[q_count].name);
		$links.removeClass('inactive');
		
		$page.html(q_count+1);
		$total.html(q_total);
		
		showHovers = true;
		
		var context = MQ.getContext();
		context = context.trim();
		
		if ( context == "medium" || context == "small" ) {
			if ( questions[q_count].type !== currentType ) {
				currentType = questions[q_count].type;

				if ( currentType === 'muscles' ) {
					$bones.fadeOut(200, function() {
						$muscles.fadeIn(200);
					});
				} else {
					$muscles.fadeOut(200, function() {
						$bones.fadeIn(200);
					});
				}
			}
		}
		
		bindClicks();
		resetTimer();
	}

	function setupGame(){
		questions.sort(function(){
			return 0.5 - Math.random();
		});
		
		$next.children('a').click(function(e){
			e.preventDefault();
			nextQuestion();
		});
	}
	
	function startGame(){
		q_correct = 0;
		q_count = -1;
		$pages.show();
		nextQuestion();
		$('html').addClass('game-in-progress');
		
		var context = MQ.getContext();
		context = context.trim();
		
		if ( context == 'small' ) {
			$.scrollTo( $('#muscles'), 500, {
				easing:'easeInOutQuad'
			});
		}
	}
	
	$startbutton.click(function(e){
		e.preventDefault();
		setupGame();
		if( lte9 ){
			$start.hide();
			$game.show();
			startGame();
		} else {
			$start.fadeOut('slow', function(){
				$game.fadeIn(300, function(){
					startGame();
				});
			});
		}
	});
	
	$startover.click(function(e){
		e.preventDefault();
		if( lte9 ){
			$results.hide();
			$start.show();
		} else {
			$results.fadeOut('slow', function(){
				$start.fadeIn(300);
			});
		}
	});
}