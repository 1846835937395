/*jslint browser: true*/
/*global $, IS */

(function() {
	var els = App.els;
	
	var $tabs,
		$panels,
		currentTab,
		$maskButton,
		$maskWipe;
	
	var dress = {};
	dress = {
		setup: function() {
			$tabs = els.$body.find('.tab-nav a');
			$panels = els.$body.find('.tab');
			currentTab = $tabs.filter('.selected').attr('href');
			$maskButton = els.$body.find('.optic-example .button');
			$maskWipe = els.$body.find('.optic-wipe');
	
			$('[rel=overthrow]').overthrow({
				afterLoad: function(o){
					o.$container.find('.button').on('click', function(e) {
						e.preventDefault();
						if ( $(this).attr('rel') === 'tab-link' ) {
							dress.switchTab( $(this).attr('href') );
							$.scrollTo( $('#page') );
						}
						dress.closeOverthrow();
					});
				}
			});
		
			$('[rel=tab-link]').on('click', function(e) {
				e.preventDefault();
				dress.switchTab( $(this).attr('href') );
			});
			
			var maskButtonText = $maskButton.text();
			
			$maskButton.click(function(e){
				e.preventDefault();
				if ( $maskWipe.hasClass('wipe-active') ){
					$maskWipe.removeClass('wipe-active')
					$maskWipe.stop().animate({
						'width': '0px'
					}, 300, 'easeInOutQuad');
					$maskButton.text(maskButtonText);
				} else {
					$maskWipe.addClass('wipe-active')
					$maskWipe.stop().animate({
						'width': '330px'
					}, 300, 'easeInOutQuad');
					$maskButton.text('Hide mask');
				}
			});
		},
		
		switchTab: function(tab) {
			if ( currentTab !== tab ) {
				$panels.hide();
				$(tab).show();
				$tabs.removeClass('selected').filter('[href='+tab+']').addClass('selected');
				currentTab = tab;
			}
		},
		
		closeOverthrow: function() {
			$('.overthrow-close').trigger('click');
		}
	};

	dress = $.extend(App.dress, dress);
})();
