var App = {
	els: {},
	lte9: false,
	encryption: {},
	home: {},
	dress: {},
	whale: {},
	light: {},
	food: {},
	fossils: {}
};

$(function(){
	var els = App.els;
	var encryption = App.encryption;
	var home = App.home;
	var dress = App.dress;
	var whale = App.whale;
	var light = App.light;
	var food = App.food;
	var fossils = App.fossils;
		
	App.els.$body = $('body');
	App.state = 'desktop';
	App.lte9 = false;
	
	var $html = $('html'),
		$body = $('body'),
		lte9 = false;
	
	Shadowbox.init({
	    skipSetup: true
	});

	var queries = [
		{
			context: 'xlarge',
			match: function() {
			},
			unmatch: function() {
			}
		},
		{
			context: 'large',
			match: function() {
			},
			unmatch: function() {
			}
		},
		{
			context: 'medium',
			match: function() {
			},
			unmatch: function() {
			}
		},
		{
			context: 'small',
			match: function() {
			},
			unmatch: function() {
			}
		}
	];
	
	MQ.init(queries);
	
	var is = {
		init : function(){
			
			is.setupIE();
			
			is.nav();
			
			switch (section){
				case "home":
					home.setup();
					break;
				case "fossils":
					fossils.setup();
					break;
				case "encryption":
					encryption.setup();
					break;
				case "dress":
					dress.setup();
					break;
				case "light":
					light.setup();
					break;
				case "whale-poo":
					whale.setup();
					break;
				case "eco":
					is.eco();
					break;
				case "cook":
					is.cook();
					break;
				case "facts":
					is.facts();
					break;
				case "degree":
					is.degree();
					break;
				case "muscle":
					is.muscle();
					break;
				case "video":
					is.video();
					break;
				case "food":
					food.setup();
					break;
				case "roadshow":
					is.roadshow();
					break;
				case null:
					break;
			}
			
		},
		setupIE : function(){
			if( $html.hasClass('lte9') ){
				lte9 = true;
				App.lte9 = true;
				is.do_lte9();
			}
			
			if( $html.hasClass('ie6') ){
				$('body').prepend('<div id="ie6"><p>Virtual Incredible Science does not support Internet Explorer version 6 or below.</p><p>Please upgrade to a more recent version of <a href="http://www.microsoft.com/ie/">this browser</a>, or try <a href="http://firefox.com">Firefox</a> or <a href="http://google.com/chrome">Chrome</a>.</div>');
			}
		},
		do_lte9 : function(){
			$('body').not('.s-home').find('footer p.copyright').css({
				color: '#fff',
				opacity: 0.35
			});
		},
		nav : function(){
			var $nav = $body.find('#nav'),
				$navTrigger = $nav.find('h2, .js-menu-toggle'),
				$menuArrow = $nav.find('.menu-arrow');
				
			$navTrigger.click(function(e){
				e.preventDefault();
				$body.toggleClass('nav-open');
				$menuArrow.toggleClass('open');
			});
		},
		roadshow : function(){
			var $roadshowImages = $('.content img');
			$roadshowImages.baseline(30);
			var currentImage = 1;
			$roadshowImages.each(function(){
				if( currentImage == 3 ){
					$(this).addClass('i-'+currentImage);
					currentImage = 0;
				} else {
					$(this).addClass('i-'+currentImage);
				}
				currentImage++;
			});
		},
		cook : function(){
			$journey = $('#journey');
			
			if( $journey.length ){
				var swf = "../../static/swf/" + $journey.data('swf') + ".swf";
				swfobject.embedSWF(swf, "journey", "800", "600", "9.0.0", "expressInstall.swf");
			}
		},
		facts : function(){
			$index = $('#index li a');

			$index.each(function(index){
				var page = index + 1;
				$(this).append('<span class="number">'+page+'</span>');
			});
		},
		eco : function(){

			var boxopened = "",
				imgopened = "",
				count = 0,
				found =  0,
				$cards = $("#cards"),
				$imgs = $("#cards img"),
				$completed = $("#completed"),
				$count = $("#count"),
				$reset = $("a.start-over"),
				$message = $("#message"),
				$header = $("#memory-header"),
				$finalcount = $("#final-count"),
				$start = $("#start");

	        function randomFromTo(from, to){
	            return Math.floor(Math.random() * (to - from + 1) + from);
	        }

	        function shuffle() {
				var $children = $cards.children();
				$children.sort(function(a,b){
					var temp = parseInt( Math.random() * $children.length, 10 );
					var isOddOrEven = temp%2;
					var isPosOrNeg = temp > 5 ? 1 : -1;
					return( isOddOrEven*isPosOrNeg );
				}).appendTo($cards);
	        }

			function setMessage(msg){
				if (MQ.getContext() != "small")
					$message.html(msg).show().delay(2000).fadeOut();
			}
			
			function resetGame() {
				shuffle();
				if( lte9 ){
					$start.hide();
				} else {
					$start.fadeOut();
				}
				$imgs.hide().css({ opacity: 1 });
				$cards.children().removeClass('matched');
				count = 0;
				$completed.hide();
				$header.fadeIn();
				$count.html("" + count);
				boxopened = "";
				imgopened = "";
				found = 0;
				setMessage('Game started!');
				return false;
			}
			
			$reset.click(function(){
				resetGame();
				return false;
			});
			
			$imgs.css({ opacity: 0}).hide();
			$cards.children().click(openCard);

			shuffle();

			function openCard() {
				var id = $(this).attr("id");
				var $opencard = $(this);
				var $openimg = $opencard.children('img').eq(0);

				if ($openimg.is(":hidden")) {
					$cards.children().unbind("click", openCard);

					$openimg.show().stop().animate({ opacity: 1 }, 200);
					
					if (imgopened == "") {
						boxopened = $openimg.data('id');
						imgopened = $openimg.data('id');
						setTimeout(function() {
							$cards.children().bind("click", openCard);
						}, 400);
					} else {
						currentopened = $openimg.data("id");
						if (imgopened != currentopened) {
							// Close again
							setTimeout(function() {
								$openimg.stop().animate({ opacity: 0 }, 200, function(){
									$(this).hide();
								});
								$cards.find('img[data-id = '+imgopened+']').stop().animate({ opacity: 0 }, 200, function(){
									$(this).hide();
								});
								boxopened = "";
								imgopened = "";
							}, 400);
						} else {
							// Found
							$openimg.stop().show().animate({ opacity: 0.6 }).parent().addClass('matched');
							$cards.find('img[data-id = '+boxopened+']').stop().show().animate({ opacity: 0.6 }).parent().addClass('matched');
							found++;
							boxopened = "";
							imgopened = "";
						}

						setTimeout(function() {
							$cards.children().bind("click", openCard);
						}, 400);
                    }

					count++;
					$count.html("" + count);

					if (found == $imgs.length / 2) {
						$finalcount.html(count);
						$header.fadeOut('fast');
						if( lte9 ){
							$completed.delay(500).show();
						} else {
							$completed.delay(500).fadeIn('fast');
						}
					}
                }
            }

		},
		degree : function(){
			$("form.quiz input:radio").uniform();
		},
		muscle : function(){
			var game = new muscleGame();
		},
		video : function(){
			$('.fitvids').fitVids();
			
			VideoJS.setupAllWhenReady();
		}
	};
	
	is.init();
	
	/* leaderboard toggle */
	
	// $('#years-7-8-top').hide();
	
	// var $leaderboardToggles = $('.leaderboard-toggle, .js-year-toggles [data-table-id]');
	
	// $leaderboardToggles.on('click', function(ev){
	// 	ev.preventDefault();
		
	// 	var $a = $(this);
		
	// 	var tableId = $a.data('table-id');
		
	// 	$('.leaderboard table, .js-leaderboard-table').hide();
	// 	$('#'+tableId).show();
		
	// 	$leaderboardToggles.removeClass('-selected');
	// 	$a.addClass('-selected');
	// });
	
});