/*jslint browser: true*/
/*global $, IS */

(function() {
	var els = App.els;
	var currentTask = 0;
	var $tasks;
	var $buttons;
	var $nav;
	
	var encryption = {};
	encryption = {
		setup: function() {
			$tasks = els.$body.find('.task');
			$buttons = els.$body.find('.continue');
			$nav = els.$body.find('.encryption-nav');
			
			$tasks.not(':first').hide();
			$tasks.data('completed', false);
			encryption.wheel();
			encryption.key();
			encryption.password();
			encryption.encipher();
			encryption.decipher();
			encryption.decipherInteractive();
			encryption.vigenereEncipher();
			encryption.vigenereDecipher();
			encryption.multiwheel();
			// encryption.reset();
			
			els.$body.find('.encryption-next-dummy').on('click', function(e) {
				e.preventDefault();
				encryption.taskComplete();
			});
			
			$buttons.on('click', function(e) {
				e.preventDefault();
				var $button = $(this);
				if ( $button.closest('.no-task').length || $button.closest('.task').data('completed') ) {
					// Go to next task
					encryption.taskComplete();
				} else {
					// Not finished yet
					alert('Please complete the task first');
				}
			});
			
			$nav.find('a[rel=tip]').hide();
			
			$nav.find('a').on('click', function(e) {
				e.preventDefault();
				var action = $(this).attr('rel');
				
				if ( action === 'skip' ) {
					encryption.taskComplete();
				} else if ( action === 'back' ) {
					encryption.goBack();
				} else if ( action === 'tip' ) {

				}
			});
		},
		
		taskComplete: function() {
			if ( !els.$body.hasClass('changing-task') ) {
				els.$body.addClass('changing-task');
				$nav.fadeOut();
				$tasks.eq(currentTask).fadeOut(function() {
					currentTask++;
					$tasks.eq(currentTask).fadeIn();
					$.scrollTo( $tasks.eq(currentTask), 800, {
						easing:'easeInOutQuad',
						offset: -30
					});
					
					encryption.updateNav();
					$nav.fadeIn();
					
					els.$body.removeClass('changing-task');
				});
			}
		},
		
		updateNav: function() {
			if ( $tasks.eq(currentTask).data('tip') ) {
				var $tipLink = $nav.find('a[rel=tip]');
				$tipLink.attr('href', '#' + $tasks.eq(currentTask).data('tip') ).show().overthrow({
					afterLoad: function(obj){
						obj.$container.find('.button').on('click', function(e) {
							e.preventDefault();
							$tipLink.overthrow('closeOverthrow');
						});
					}
				});
			} else {
				$nav.find('a[rel=tip]').hide();
			}
			
			if ( currentTask > 0 ) {
				$nav.find('a[rel=back]').show();
			} else {
				$nav.find('a[rel=back]').hide();
			}
			
			if ( currentTask === ($tasks.length - 1) ) {
				$nav.find('a[rel=skip]').hide();
			} else {
				$nav.find('a[rel=skip]').show();
			}
		},

		goBack: function() {
			if ( !els.$body.hasClass('changing-task') ) {
				els.$body.addClass('changing-task');
				$nav.fadeOut();
				$tasks.eq(currentTask).fadeOut(function() {
					currentTask--;
					$tasks.eq(currentTask).fadeIn();
					$.scrollTo( $tasks.eq(currentTask), 800, {
						easing:'easeInOutQuad',
						offset: -30
					});
					
					encryption.updateNav();
					$nav.fadeIn();
					
					els.$body.removeClass('changing-task');
				});
			}
		},

		wheel: function() {
			var rotationSnap = 13.846153846;
			var answer = 'hacker';
			var $answerForm = $('.decipher-form-interactive');
			var $answerField = $('.decipher-form-interactive input[type=text]');
			var $answerButton = $('.decipher-form-interactive input[type=submit]');
			var $answerStatus = $('.decipher-form-interactive .status');
			var statusDefault = '&nbsp;';
			
			$('.wheel-inner').each(function() {
				Draggable.create($(this), {
			    	type: "rotation",
					throwProps: true,
					snap: function(endValue) { 
						return Math.round(endValue / rotationSnap) * rotationSnap;
					}
				});
			});
			
			$answerForm.on('submit', function(e) {
				e.preventDefault();
				if ( $answerField.val().toLowerCase() === answer ) {
					$tasks.eq(currentTask).addClass('decipher-success').addClass('task-complete').data('completed', true);;
					$answerStatus.html('Correct!<br/>The deciphered word was <strong>' + answer.toUpperCase() + '</strong><br/>The key was <strong>Q</strong>.');
					$answerField.attr('disabled', 'disabled');
					$answerButton.attr('disabled', 'disabled');
				} else {
					$tasks.eq(currentTask).addClass('decipher-fail');
					$answerStatus.text('Incorrect — please try again.');

					setTimeout(function(){
						$tasks.eq(currentTask).removeClass('decipher-fail');
						$answerStatus.html(statusDefault);
						$answerField.val('').focus();
					}, 500);
				}
			});
		},
		
		key: function() {
			var $padlock = els.$body.find('#padlock');
			var $keys = els.$body.find('.key');
			var $status = els.$body.find('#status');
			var statusText = '&nbsp;';
			var $successMessage = els.$body.find('.task-success-modal')

			$keys.draggable({
				containment: 'article',
				cursor: 'move',
				stack: '.key',
				revert: true
			});

			$padlock.droppable({
				accept: '.key',
				drop: handleDrop,
				hoverClass: 'padlock-hover'
			});

			function handleDrop(event, ui){
				var draggable = ui.draggable;
				var keyPadlock = ui.draggable.data('belongs-to');

				if( keyPadlock == 'padlock' ){
					// Correct key
					dropSuccess(ui.draggable, $(this));
				} else {
					dropFail(ui.draggable, $(this));
				}
			}

			function dropSuccess(key, padlock){
				padlock.addClass('key-success');
				// $status.text('Success!');
				$tasks.eq(currentTask).addClass('task-complete').data('completed', true);
			
				key.addClass('correct').draggable('disable').draggable('option', 'revert', false);
				$keys.draggable( "disable" );
				
				var $modal = $('<a href="#key-matching-modal"></a>');
				
				setTimeout(function() {
					$modal.overthrow({
						afterLoad: function(obj){
							obj.$container.find('.button').on('click', function(e) {
								e.preventDefault();
								// obj.$container.find('.overthrow-close').trigger('click');
								$modal.overthrow('closeOverthrow');
								setTimeout(function() {
									encryption.taskComplete();
								}, 300);
							});
						}
					}).trigger('click');
				}, 200);
			}
		
			function dropFail(key, padlock){
				padlock.addClass('key-fail');
				$status.text('Incorrect — please try again.');

				setTimeout(function(){
					padlock.removeClass('key-fail');
					$status.html(statusText);
				}, 500);
			}
		},
		
		password: function() {
			var $thisTask = $tasks.is('.password-key');
			var $passwordForm = $('.password-form');
			var $passwordField = $('#password');
			var $passwordButton = $passwordForm.find('input[type=submit]');
			var $passwordStatus = $('#password-status');
			var password = '121';
			var statusDefault = $passwordStatus.text();
			
			$passwordForm.on('submit', function(e) {
				e.preventDefault();
				if ( $passwordField.val() === password ) {
					$tasks.eq(currentTask).addClass('password-success').addClass('task-complete').data('completed', true);;
					$passwordStatus.html('Correct! The password was ' + password);
					$passwordField.attr('disabled', 'disabled');
					$passwordButton.attr('disabled', 'disabled');
					
					setTimeout(function(){
						encryption.taskComplete();
					}, 1000);
				} else {
					$tasks.eq(currentTask).addClass('password-fail');
					$passwordStatus.text('Incorrect — please try again.');

					setTimeout(function(){
						$tasks.eq(currentTask).removeClass('password-fail');
						$passwordStatus.html(statusDefault);
						$passwordField.val('').focus();
					}, 1000);
				}
			});
			
			$('.password-digits').data('completed-count', 0);
			encryption.passwordRadios( $('.password-4digits'), '16' );
			encryption.passwordRadios( $('.password-million'), '20' );
		},
		
		passwordRadios: function(radioGroup, answer) {
			var $radioGroup = radioGroup;
			var $radios = $radioGroup.find('input[type=radio]');
			var questionsToComplete = 2;
			
			$radios.on('change', function() {
				if ( !$radioGroup.data('completed') ) {
					var $radio = $(this);
					if ( $radio.val() === answer ) {
						$radio.parent('li').addClass('password-correct');
					} else {
						setTimeout(function() {
							$radio.parent('li').addClass('password-incorrect');
							$radios.filter('[value='+answer+']').parent('li').addClass('password-correct');
						}, 200);
					}
					$radioGroup.data('completed', true);
					
					// Check if all questions have been answered
					var completedCount = $tasks.eq(currentTask).data('completed-count');
					if ( (completedCount + 1) >= questionsToComplete ) {
						// Finished all tasks
						$tasks.eq(currentTask).data('completed', true).addClass('task-complete');
						setTimeout(function() {
							encryption.taskComplete();
						}, 1500);
					} else {
						$tasks.eq(currentTask).data('completed-count', (completedCount + 1));
					}
				}
			});
			
		},
		
		encipher: function() {
			encryption.codeWheelTask('encipher', 'axeeh');
		},

		decipher: function() {
			encryption.codeWheelTask('decipher', 'crack');
		},
		
		decipherInteractive: function() {
			encryption.codeWheelTask('decipher-interactive', 'hacker');
		},
		
		vigenereEncipher: function() {
			encryption.codeWheelTask('vigenere-encipher', 'pvoqkibt', true);
		},
		
		vigenereDecipher: function() {
			encryption.codeWheelTask('vigenere-decipher', 'mother');
		},
		
		multiwheel: function() {
			encryption.codeWheelTask('final-task', 'monkey');
		},
		
		codeWheelTask: function(formPrefix, answer, showSuccessMessage) {
			var answer = answer;
			var $answerForm = $('.' + formPrefix + '-form');
			var $answerField = $('.' + formPrefix + '-form input[type=text]');
			var $answerButton = $('.' + formPrefix + '-form input[type=submit]');
			var $answerStatus = $('.' + formPrefix + '-form .status');
			var statusDefault = $answerStatus.text();
			if ( showSuccessMessage ) {
				var $successMessage = $answerForm.closest('.code-wheel-description').find('.success-text');
			}
			
			$answerForm.on('submit', function(e) {
				e.preventDefault();
				if ( $answerField.val().toLowerCase() === answer ) {
					$tasks.eq(currentTask).addClass('result-success').addClass('task-complete').data('completed', true);
					$answerStatus.html('Correct!');
					if ( showSuccessMessage ) {
						$successMessage.show();
						setTimeout(function() {
							encryption.taskComplete();
						}, 3000);
					} else {
						setTimeout(function() {
							encryption.taskComplete();
						}, 1000);						
					}
				} else {
					$tasks.eq(currentTask).addClass('result-fail');
					$answerStatus.text('Incorrect — please try again.');

					setTimeout(function(){
						$tasks.eq(currentTask).removeClass('result-fail');
						$answerStatus.html(statusDefault);
						$answerField.val('').focus();
					}, 1500);
				}
			});
		}
	};

	encryption = $.extend(App.encryption, encryption);
})();
