$(document).ready(factsMobile);

function factsMobile() {
    if ($('body').hasClass('s-facts')) {
    	var $window = $(window);
        movePageContent();
    	
    	$(window).resize(movePageContent);

        function movePageContent() {
        	var windowsize = $window.width();
            if (windowsize < 640) {
                var factTitle = $(".page-left").find(".selected")[0];
                var factContent = $(".page-right");
                factContent.insertAfter(factTitle);
                $.scrollTo(factTitle);
            }
        	else {
                var factContent = $(".page-right");		
    			$("article").append(factContent);  
            }
        }
    }
}