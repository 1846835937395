/*jslint browser: true*/
/*global $, IS */

(function() {
	var els = App.els;
	var $window = $(window);
	var $trigger;
	
	var light = {};
	light = {
		setup: function() {
			$trigger = $('#introduction-trigger');
			
			$trigger.overthrow({
				afterLoad: function(o) {
					o.$container.find('.button').on('click', function(e) {
						e.preventDefault();
						light.closeOverthrow();
					});
					$('#notes').animate({ bottom: '0'} , 500);
				}
			});
			
			$trigger.trigger('click');
			
			if ( !Modernizr.touchevents ) {
				$(document).on('mousemove', function(e){
					var windowsize = $window.width();
    				if (windowsize > 640) {
						$('.light').css({
							left:  e.pageX,
							top:   e.pageY
						});
					}
					else {
						$('.light').css({
							left:  '50%',
							top:   '300px'
						});
					}
				});
			}
		},
		
		positionLight: function() {
			var windowsize = $window.width();
			if (windowsize > 640) {
				$('.light').css({
					left:  ($(document).width() / 2),
					top:   '50%'
				});
			} else {
				$('.light').css({
					left:  '50%',
					top:   '50%'
				});
			}
		},
		
		startTouchContent: function() {
			
		},
		
		closeOverthrow: function() {
			$('.overthrow-close').trigger('click');
			
			if ( Modernizr.touchevents ) {
				light.positionLight();
			}
			
			$(window).on('resize', light.positionLight);
		}
	};

	light = $.extend(App.light, light);
})();
